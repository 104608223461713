import React from 'react';
import ViewAnnotationHeader from "components/container/recogito/popup/ViewAnnotation/ViewAnnotationHeader";
import {CardActions, CardContent} from "@mui/material";
import ViewAnnotationContent from "components/container/recogito/popup/ViewAnnotation/ViewAnnotationContent";
import ViewAnnotationReplies from "components/container/recogito/popup/ViewAnnotation/ViewAnnotationReplies";
import ViewAnnotationTags from "components/container/recogito/popup/ViewAnnotation/ViewAnnotationTags";
import ViewAnnotationActions from "components/container/recogito/popup/ViewAnnotation/ViewAnnotationActions";
import Card from "@mui/material/Card";

const ViewCardAnnotation = (props) => {
    return (
        <Card>
            <ViewAnnotationHeader {...props} />
            <CardContent>
                <ViewAnnotationContent {...props} />
                <ViewAnnotationReplies {...props} />

                {/* Tags are developed, but they are not required for our delivery */}
                {/*<ViewAnnotationTags {...props} />*/}

            </CardContent>
            <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                {
                    // we only show annotation actions if we are not in the fake mode
                    !props.isFake &&
                    <ViewAnnotationActions {...props} />
                }
            </CardActions>
        </Card>
    );
};

export default ViewCardAnnotation;